// export const IS_MOBILE = window.screen.width < 768;

export const isMobile = () => {
    return window.screen.width < 768;
  };
  
//   // Usage
//   if (isMobile()) {
//     console.log('This is a mobile device');
//   }
  
